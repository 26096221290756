// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../node_modules/@arshad/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../node_modules/@arshad/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-tag-query-js": () => import("./../node_modules/@arshad/gatsby-theme-blog-core/src/templates/tag-query.js" /* webpackChunkName: "component---node-modules-arshad-gatsby-theme-blog-core-src-templates-tag-query-js" */),
  "component---node-modules-arshad-gatsby-theme-page-core-src-templates-page-query-js": () => import("./../node_modules/@arshad/gatsby-theme-page-core/src/templates/page-query.js" /* webpackChunkName: "component---node-modules-arshad-gatsby-theme-page-core-src-templates-page-query-js" */)
}

